















import { defineComponent, toRefs } from '@nuxtjs/composition-api'
import Link from '@/components/Link.vue'
import { defineProps } from '@/helpers/defineProps'

type BlockTagProps = {
  linkText: string
  linkUrl: string
  isActive: boolean
  isAccented: boolean
}

export default defineComponent({
  name: 'BlockTag',
  components: { Link },
  props: defineProps<BlockTagProps>({
    linkText: {
      default: '',
    },
    linkUrl: {
      default: '',
    },
    isActive: {
      default: false,
    },
    isAccented: {
      default: false,
    },
  }),
  setup(props, { emit }) {
    const { isActive } = toRefs(props)

    const onClick = () => {
      !isActive.value && emit('input')
    }

    return {
      onClick,
    }
  },
})
