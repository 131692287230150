














































import { defineComponent, computed } from '@nuxtjs/composition-api'
import { defineProps } from '@/helpers/defineProps'
import { PictureSizes } from '@/contracts/PictureSizes'
import { CardIconImage as CardIconImageContract } from '@/contracts/CardIconImage'
import Link from '@/components/Link.vue'
import { HOVER_COLORS } from '@/contracts/Cards'
import BlockButtons from '@/components/BlockButtons.vue'

export default defineComponent({
  components: { BlockButtons, Link },
  props: defineProps<CardIconImageContract>({
    image: {
      default: '',
    },
    alt: {
      default: '',
    },
    linkText: {
      default: '',
    },
    linkUrl: {
      default: '',
    },
    title: {
      default: '',
    },
    hover: {
      default: '',
    },
    background: {
      default: '',
    },
    actionButtons: {
      default: () => [],
    },
  }),
  setup(props) {
    const sizes: PictureSizes = {
      sm: [90, 90],
      md: [130, 130],
      lg: [260, 260],
    }
    const styles = computed(() => ({
      '--bg-icon-image-hover': props.background || 'var(--bg-secondary)',
    }))
    return {
      styles,
      HOVER_COLORS,
      sizes,
    }
  },
})
