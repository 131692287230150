


















import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'

@Component
class PopupFromRight extends Vue {
  @Prop() readonly isShown: boolean = false
  @Prop() readonly isNoPadding: boolean = false
  static options: any
}

export default defineComponent({
  name: 'PopupFromRight',
  props: PopupFromRight.options.props,
  setup(props, { emit }) {
    const onClose = (): void => {
      emit('hide-popup')
    }

    return {
      onClose,
    }
  },
})
