































import { defineComponent } from '@nuxtjs/composition-api'
import BlockTag from '@/components/Tags/BlockTag.vue'
import { defineProps } from '@/helpers/defineProps'

type TagDataStructure = {
  linkText?: string
  linkUrl?: string
  id?: string
  isAccented?: boolean
}

type TagRowProps = {
  tagsData: TagDataStructure[]
  label: string
  value: string
  variant: 'outline' | 'default'
  error: boolean | string
}

export default defineComponent({
  name: 'BlockTagRow',
  components: {
    BlockTag,
  },
  props: defineProps<TagRowProps>({
    tagsData: {
      default: () => [] as TagDataStructure[],
    },
    variant: {
      default: 'default',
    },
    value: {
      default: '',
    },
    label: {
      default: '',
    },
    error: {
      default: false,
    },
  }),
  methods: {
    onChange(item: TagDataStructure) {
      this.$emit('input', item)
    },
  },
})
